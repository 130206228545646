
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import TrainerFilter from '@/components/dropdown/trainerFilter.vue';
  import LookupNameIds from '@/store/enums/settingsEnums';

  export default defineComponent({
    name: 'trainers-listing',
    components: {
      Datatable,
      TrainerFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const searchItems = async (inputText) => {
        await store.dispatch(Actions.UPDATE_TRAINERS_FILTER, {
          name: inputText,
        });
        await store.dispatch(Actions.GET_TRAINERS);
        await store.dispatch(Actions.EXPORT_TRAINERS);
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('trainersListing'), [
          translate('operations'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_TRAINERS_STORE);
        });
        loading.value = true;
        await store.dispatch(Actions.GET_TRAINERS);
        await store.dispatch(Actions.GET_ALL_SETTINGS_CLASS_CATEGORY);
        await store.dispatch(Actions.GET_ALL_ARENAS);
        await store.dispatch(Actions.GET_ALL_TRAINERS, {
          categrgoyId: undefined,
          active: true,
        });
        await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
          LookupNameIds.STATUS,
        ]);

        loading.value = false;

        await store.dispatch(Actions.EXPORT_TRAINERS);
      });

      // const formatDate = (date) => {
      //   return new Date(date).toISOString().slice(0, 10);
      // };

      const excelHeader = reactive({
        Name: 'name',
        Gender: 'genderName',
        'Category Name': 'classCategoryName',
        Status: 'statusName',
        // 'Date Of Birth': {
        //   field: 'createdAt',
        //   callback: (value) => {
        //     return formatDate(value);
        //   },
        // },
      });
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('email'),
          key: 'email',
          sortable: true,
        },
        {
          name: translate('gender'),
          key: 'genderName',
          sortable: true,
        },
        {
          name: translate('Category Name'),
          key: 'classCategoryName',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'statusName',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteTrainer = async (trainer) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_TRAINER, trainer.id);
          await store.dispatch(Actions.GET_TRAINERS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const editTrainer = (id) => {
        router.push({ name: 'trainer-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_TRAINERS_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_TRAINERS);
        loading.value = false;
      };

      const searchTrainer = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_TRAINERS_FILTER, query);
        await store.dispatch(Actions.UPDATE_TRAINERS_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_TRAINERS);
        await store.dispatch(Actions.EXPORT_TRAINERS);

        loading.value = false;
      };
      const onReset = async (query) => {
        await store.dispatch(Actions.UPDATE_TRAINERS_FILTER, query);
        await store.dispatch(Actions.UPDATE_TRAINERS_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_TRAINERS);
        await store.dispatch(Actions.EXPORT_TRAINERS);
      };

      return {
        tableData: computed(() => store.getters.trainersList),
        tableHeader,
        inputText: '',
        searchItems,
        deleteTrainer,
        translate,
        totalItems: computed(() => store.getters.trainersListCount),
        pageChanged,
        goTo,
        editTrainer,
        loading,
        excelData: computed(() => store.getters.exportTrainersList),
        excelHeader,
        can,
        searchTrainer,
        onReset,
      };
    },
  });
